<template>
  <div>
    <img src="../assets/images/404.jpg" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
div{
  width: 100%;
  background-color: #FDD100;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>>